import { useStaticQuery, graphql } from "gatsby";

const useCards = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryCard {
        allContentfulDataCabana {
          nodes {
            id
            title
            persons
            slug
            shortDesc
            longDesc
            image {
              gatsbyImageData(width: 370, height: 282)
            }
            imageContainer {
              gatsbyImageData(height: 463)
            }
            services
            beds
          }
        }
      }
    `
  );
  return data.allContentfulDataCabana.nodes.map((i) => ({
    id: i.id,
    title: i.title,
    shortDesc: i.shortDesc,
    longDesc: i.longDesc,
    image: i.image.gatsbyImageData,
    imageContainer: i.imageContainer,
    beds: i.beds,
    persons: i.persons,
    tags: i.services,
    slug: i.slug,
  }));
};

export default useCards;
