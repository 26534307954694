import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import useCards from "../utils/useCards";
import { CardCabin } from "../components/Cards";

const CardPreview = () => {
  const data = useCards();
  console.log(data);
  return (
    <Layout>
      <Seo title="Cabañas" />

      <div className="pb-8">
        <h2 className="title lg:subtitle text-center lg:py-0">
          Nuestras Cabañas.
        </h2>
        <p className="paragraph text-center lg:-mt-4 lg:mb-6">
          En Villas Cheyos contamos con todo lo que necesitas para sentirte como
          en casa.
        </p>
        <div
          className={`grid place-items-center pt-16 lg:flex lg:flex-wrap lg:gap-16 lg:items-start lg:justify-center gap-16 `}
        >
          {data.map((i) => (
            <CardCabin
              id={i.id}
              title={i.title}
              shortDesc={i.shortDesc}
              longDesc={i.longDesc}
              persons={i.persons}
              slug={i.slug}
              tags={i.tags}
              beds={i.beds}
              image={i.image}
              imageContainer={i.imageContainer}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default CardPreview;
