import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  IconAC,
  IconBath,
  IconBeach,
  IconBed,
  IconNature,
  IconPark,
  IconPeople,
  IconTV,
  IconWifi,
} from "../atoms/Icons";
import { Link } from "gatsby";

export const CardServicies = () => {
  return (
    <div className="paragraph flex flex-col gap-2 lg:gap-4 items-center text-center lg:items-start lg:text-left max-w-xs my-4">
      <IconBeach />
      <p className="lg:leading-auto px-4 lg:px-0 lg:pr-8">
        Nuestras Cabañas están ubicadas sobre la playa a pasos de una de las
        mejores olas del Pacífico Mexicano.
      </p>
    </div>
  );
};

export const CardServiciesParking = () => {
  return (
    <div className="paragraph flex flex-col gap-2 lg:gap-4 items-center text-center lg:items-start lg:text-left max-w-xs my-4">
      <IconPark />
      <p className="lg:leading-auto px-4 lg:px-0 lg:pr-8">
        Nuestras Villas cuentan con estacionamiento propio.
      </p>
    </div>
  );
};

export const CardServiciesInternet = () => {
  return (
    <div className="paragraph flex flex-col gap-2 lg:gap-4 items-center text-center lg:items-start lg:text-left max-w-xs my-4">
      <IconWifi fill={"#E77A3F"} />
      <p className="lg:leading-auto px-4 lg:px-0 lg:pr-8">
        Tenemos conexión a internet, y un espacio de cowork disponible para tí.
      </p>
    </div>
  );
};

export const CardServiciesNature = () => {
  return (
    <div className="paragraph flex flex-col gap-2 lg:gap-4 items-center text-center lg:items-start lg:text-left max-w-xs my-4">
      <IconNature />
      <p className="lg:leading-auto px-4 lg:px-0 lg:pr-8">
        Estamos inmersos en un entorno natural, tranquilo e ideal para el
        descanso, rodeados de plantas, árboles y naturaleza.
      </p>
    </div>
  );
};

export const CardCabin = ({
  image,
  shortDesc,
  beds,
  persons,
  title,
  slug,
  tags,
}) => {
  return (
    <Link className="animation-card" to={`/card/${slug}`}>
      <div className="max-w-sm ">
        <GatsbyImage
          height={282}
          image={image}
          placeholder="tracedSVG"
          alt="image cabin"
          className="w-full"
        />
        <div className="flex flex-col gap-2">
          <p className="paragraph font-bold pt-4">{title}</p>

          <p className="paragraph pb-4">{shortDesc}</p>
        </div>

        <div className="grid grid-cols-2">
          <div className="flex items-center mt-2">
            <IconBed fill="black" />
            <p className="paragraph ml-2 mt-0.5">
              {beds} {beds > 1 ? "camas" : "cama"}
            </p>
          </div>
          <div className="flex items-center mt-2">
            <IconPeople fill="black" />
            <p className="paragraph ml-2 mt-0.5">{persons} personas</p>
          </div>

          {tags.includes("wifi") && (
            <div className="flex items-center mt-2">
              <IconWifi fill="black" />
              <p className="paragraph ml-2 mt-0.5">Wi-Fi</p>
            </div>
          )}

          {tags.includes("baño") && (
            <div className="flex items-center mt-2">
              <IconBath fill="black" />
              <p className="paragraph ml-2 mt-0.5">Baño privado</p>
            </div>
          )}
          {tags.includes("tv") && (
            <div className="flex items-center mt-2">
              <IconTV />
              <p className="paragraph ml-2 mt-0.5">TV</p>
            </div>
          )}
          {tags.includes("clima") && (
            <div className="flex items-center mt-2">
              <IconAC />
              <p className="paragraph ml-2 mt-0.5">Clima</p>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
